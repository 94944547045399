import {Injectable} from '@angular/core';

@Injectable()
export class AppState {
    // tslint:disable-next-line:variable-name
    _state = {};

    get state() {
        return this._state = this.clone(this._state);
    }

    private clone(object) {
        return JSON.parse(JSON.stringify(object));
    }

    get(prop?) {
        const state = this.state;
        return state.hasOwnPropertyprop( prop) ? state[prop] : state;
    }

    set(prop, value) {
        return this._state[prop] = value;
    }
}
