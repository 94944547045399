import { Component, NgZone } from '@angular/core';
import { Platform, NavController, Config } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppState } from './app.global';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { HelperService } from './services/helper.service';
import { ApiService } from './services/api.service';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import OneSignal from '../../plugins/onesignal-cordova-plugin';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isForWeb = false;

  constructor(
    private router: Router,
    private platform: Platform,
    protected navController: NavController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    public global: AppState,
    public deeplinks: Deeplinks,
    private helperService: HelperService,
    public config: Config,
    public zone: NgZone,
    private apiService: ApiService,
    private ga: GoogleAnalytics
  ) {
    if (this.helperService.isForWeb()) {
      this.isForWeb = true;
    }
    this.initializeApp();
  }

  initializeApp() {
    const authToken = localStorage.getItem('user.AuthToken') || false;
    if (!authToken) {
      this.router.navigate(['login']);
    }
    this.translate.setDefaultLang('ru');
    this.translate.addLangs(['ru', 'en', 'kk', 'tr']);
    if (this.isForWeb) {
      const lang = this.helperService.getParamValueQueryString('lang');
      if (lang) {
        this.translate.use(lang);
      } else {
        this.translate.use(this.translate.getBrowserLang());
      }
    } else {
      if (localStorage.getItem('lang')) {
        this.translate.use(localStorage.getItem('lang'));
      } else {
        this.translate.use(this.translate.getBrowserLang());
      }
    }

    this.platform.ready().then(() => {
      /*setTimeout(() => {
              this.ga.startTrackerWithId('G-9L7M11ECRY')
                  .then(() => {
                      alert('Google analytics is ready now');
                      this.ga.trackView('test');
                      // Tracker is ready
                      // You can now track pages or set additional information such as AppVersion or UserId
                  })
                  .catch(e => alert('Error starting GoogleAnalytics' + e));
          }, 5000);*/
      localStorage.removeItem('app.Theme'); // Remove white theme. Delete after 2.0.9
      const storageValue = localStorage.getItem('app.Theme') || false;
      this.global.set('app.Theme', true);
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#f9fafb');
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.helperService.lockOrientation();
      this.translate.get('BACK').subscribe((backLabel) => {
        this.config.set('backButtonText', backLabel);
      });
      if (!this.isForWeb) {
        this.setupOnesignal();
      }
      if (!this.isForWeb) {
        this.setupDeeplinks();
      }
    });
  }
  setupOnesignal() {
    window['plugins'].OneSignal.setLogLevel(6, 0);

    const notificationOpenedCallback = (jsonData) => {
      // console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      this.router.navigate(['/notifications']);
    };

    const notificationReceivedCallback = (jsonData) => {
      // console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      const CustomerID = localStorage.getItem('user.CustomerID');
      this.apiService.getNotifications(Number(CustomerID)).then((res: []) => {
        if (res.length) {
          this.helperService.switchNotification(
            res.some((item: any) => {
              return !item.isRead;
            })
          );
        }
      });
    };

    // Set your iOS Settings
    const iosSettings = {};
    iosSettings['kOSSettingsKeyAutoPrompt'] = false;
    iosSettings['kOSSettingsKeyInAppLaunchURL'] = false;

    OneSignal.setAppId('4b5df7a7-8d5a-4596-8863-63840b81b118');
    OneSignal.setNotificationOpenedHandler(notificationOpenedCallback);
    // window['plugins'].OneSignal.handleNotificationReceived(notificationReceivedCallback);
    // OneSignal.iOSSettings(iosSettings);
    OneSignal.promptForPushNotificationsWithUserResponse();
    // window['plugins'].OneSignal.inFocusDisplaying(window['plugins'].OneSignal.OSInFocusDisplayOption.Notification);
    // window['plugins'].OneSignal.endInit();
    /*OneSignal.getIds((ids) => {
            localStorage.setItem('PlayerID', ids.userId);
            localStorage.setItem('push_token', ids.pushToken);
            console.log(ids);
        });*/
    // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt.
    // We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
    /*window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
            console.log('User accepted notifications: ' + accepted);
        });*/
  }
  setupDeeplinks() {
    this.deeplinks
      .routeWithNavController(this.navController, {
        '/payment-details': { status: true },
        '/clarification/:id': '/clarification',
        '/pre-approved/:id': '/pre-approved',
        '/certificate/:id': '/certificate',
      })
      .subscribe(
        (match) => {
          this.zone.run(() => {
            console.log(match);
            if (match.$link.url.indexOf('payment') > -1) {
              this.router.navigate(['/payment-details/' + match.$args.id]);
            } else if (match.$route.indexOf('clarification') > -1) {
              this.router.navigate(['/clarification/' + match.$args.id]);
            } else if (match.$route.indexOf('pre-approved') > -1) {
              this.router.navigate(['/pre-approved/' + match.$args.id]);
            } else if (match.$route.indexOf('certificate') > -1) {
              this.router.navigate(['/certificate/' + match.$args.id]);
            }
          });
        },
        (nomatch) => {
          // alert(JSON.stringify(nomatch));
        }
      );
  }
}
