import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Config, Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import {
  InAppBrowser,
  InAppBrowserOptions,
} from '@ionic-native/in-app-browser/ngx';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import * as moment from 'moment-timezone';

declare let AudioToggle: any;
declare let plugins: any;
declare let chrome: any;

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  hasPermission: boolean;
  permission: any;
  mode: TestModes;
  private langs = [];
  private hasNotification = false;
  public continueTestsList = [];
  public deviceId: any;
  public currentReport = {};
  public countries = [
    { code: '+7', name: 'Казахстан', id: 1, countryCode: 'KZ' },
    { code: '+998', name: 'Узбекистан', id: 3, countryCode: 'UZ' },
    { code: '+971', name: 'Arab Emirates', id: 8, countryCode: 'UAE' },
  ];

  constructor(
    private router: Router,
    public plt: Platform,
    private device: Device,
    private androidPermissions: AndroidPermissions,
    private inAppBrowser: InAppBrowser,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private screenOrientation: ScreenOrientation,
    private apiService: ApiService,
    public config: Config,
    private ngZone: NgZone
  ) {}

  isForWeb() {
    return true;
  }

  getPaymentSystem(isRecurring) {
    const country = this.getSelectedCountry();
    switch (country) {
      case 'KZ':
        return isRecurring
          ? PaymentSystem.FreedomBank
          : PaymentSystem.FreedomPay;
      case 'UZ':
        return isRecurring
          ? PaymentSystem.FreedomPay
          : PaymentSystem.FreedomPay;
      case 'UAE':
        return isRecurring
          ? PaymentSystem.TelegramPay
          : PaymentSystem.TelegramPay;
    }
  }

  getCurrency() {
    const data = localStorage.getItem('user.Country');
    if (data) {
      const { Currency } = JSON.parse(data);
      return Currency;
    } else {
      return '';
    }
  }

  getCountry() {
    const data = localStorage.getItem('user.Country');
    if (data) {
      const { Id } = JSON.parse(data);
      switch (Id) {
        case 1:
          return 'kk';
        case 3:
          return 'uz';
        default:
          return 'kk';
      }
    } else {
      return 'kk';
    }
  }

  getSelectedCountry = () =>
    !!localStorage.getItem('COUNTRY_CODE')
      ? localStorage.getItem('COUNTRY_CODE')
      : 'KZ';

  getReportStatus(report) {
    const ExpressTestKeys = Object.keys(this.getExternalTestStatuses()).map(
      (key) => {
        return key.charAt(0).toUpperCase() + key.slice(1);
      }
    );
    let passed = 0;
    let names = '';
    let testsCount = 0;

    Object.keys(report).map((key, ind) => {
      const isExpressKey = ExpressTestKeys.find((testKey) => {
        return key.toLowerCase() === testKey.toLowerCase();
      });
      if (report && isExpressKey) {
        switch (report[key]) {
          case 2:
            passed++;
            testsCount++;
            break;
          case 0 || 1:
            switch (key) {
              case 'sensor':
                key = 'Сенсорный экран';
                break;
              default:
                break;
            }
            names += '\n' + key;
            break;
          default:
            break;
        }
      }
    });

    if (report['isValid'] === false) {
      return {
        status: reportStatus.OUTDATED,
        names,
      };
    }
    if (testsCount < 2) {
      return {
        status: reportStatus.INVALID,
        names,
      };
    } else {
      return {
        status: reportStatus.VALID,
      };
    }
  }

  toBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        }
      };
      reader.onerror = (error) => reject(error);
    });
  }

  switchNotification(state) {
    this.ngZone.run(() => {
      this.hasNotification = state;
    });
  }

  getNotification() {
    return this.hasNotification;
  }

  getTests() {
    const testList = [
      {
        title: 'Экран',
        fields: [
          {
            title: 'Сенсорный экран',
            key: 'DISPLAY',
            icon: 'hand-right',
            path: 'screen-sensor',
            status: 'sensor',
          },
        ],
      },
      {
        title: 'Звук',
        fields: [
          // {
          //   title: 'Громкая связь',
          //   key: 'SPEAKER_LOUD',
          //   icon: 'volume-high',
          //   path: 'sound-speaker-loud',
          //   status: 'loudSpeaker',
          // },
          // {
          //   title: 'Динамик',
          //   key: 'SPEAKER',
          //   icon: 'ear',
          //   path: 'sound-speaker',
          //   status: 'speaker',
          // },
          {
            title: 'Микрофон',
            key: 'MICROPHONE',
            icon: 'mic',
            path: 'sound-mic',
            status: 'mic',
          },
          // {
          //   title: 'Наушники',
          //   key: 'HEADSET',
          //   icon: 'headset',
          //   path: 'sound-headphones',
          //   status: 'headphones',
          // },
        ],
      },
      // {
      //   title: 'Датчик движения',
      //   fields: [
      //     {
      //       title: 'Акселерометр',
      //       key: 'ACCELEROMETER',
      //       icon: 'speedometer',
      //       path: 'sensor-accelerometer',
      //       status: 'accelerometer',
      //     },
      //     {
      //       title: 'Компас',
      //       key: 'COMPASS',
      //       icon: 'compass',
      //       path: 'sensor-compass',
      //       status: 'compass',
      //     },
      //     {
      //       title: 'Гироскоп',
      //       key: 'GYROSCOPE',
      //       icon: 'planet',
      //       path: 'sensor-gyroscope',
      //       status: 'gyroscope',
      //     },
      //   ],
      // },
      // {
      //   title: 'Подключение',
      //   fields: [
      //     {
      //       title: 'Wifi',
      //       key: 'WIFI',
      //       icon: 'wifi',
      //       path: 'connection-wifi',
      //       status: 'wifi',
      //     },
      //     {
      //       title: 'Bluetooth',
      //       key: 'BLUETOOTH',
      //       icon: 'bluetooth',
      //       path: 'connection-bluetooth',
      //       status: 'bluetooth',
      //     },
      //     {
      //       title: 'Сотовая связь',
      //       key: 'CELLULAR',
      //       icon: 'cellular',
      //       path: 'connection-cellular',
      //       status: 'cellular',
      //     },
      //     {
      //       title: 'GPS',
      //       key: 'GPS',
      //       icon: 'navigate',
      //       path: 'connection-gps',
      //       status: 'gps',
      //     },
      //   ],
      // },
      // {
      //   title: 'Аппаратные средства',
      //   fields: [
      //     {
      //       title: 'Датчик освещённости',
      //       key: 'LIGHT_SENSOR',
      //       icon: 'sunny',
      //       path: 'hardware-light-sensor',
      //       status: 'lightSensor',
      //     },
      //     {
      //       title: 'Зарядное устройство',
      //       key: 'CHARGER',
      //       icon: 'battery-charging',
      //       path: 'hardware-charger',
      //       status: 'charger',
      //     },
      //     {
      //       title: 'Аппаратные кнопки',
      //       key: 'BUTTONS',
      //       icon: 'radio-button-on',
      //       path: 'hardware-buttons',
      //       status: 'buttons',
      //     },
      //     {
      //       title: 'Вибрация',
      //       key: 'VIBRATION',
      //       icon: 'radio',
      //       path: 'hardware-vibration',
      //       status: 'vibration',
      //     },
      //     {
      //       title: 'Датчик расстояния',
      //       key: 'PROXIMITY',
      //       icon: 'create',
      //       path: 'hardware-proximity',
      //       status: 'proximity',
      //     },
      //     {
      //       title: 'Отпечаток пальца',
      //       key: 'FINGERPRINT',
      //       icon: 'finger-print',
      //       path: 'hardware-fingerprint',
      //       status: 'fingerprint',
      //     },
      //   ],
      // },
      // {
      //   title: 'Камера',
      //   fields: [
      // {
      //   title: 'Фронтальная камера',
      //   key: 'CAMERA',
      //   icon: 'camera',
      //   path: 'camera-front',
      //   status: 'front',
      // },
      // {
      //   title: 'Задняя камера',
      //   key: 'REAR',
      //   icon: 'camera-outline',
      //   path: 'camera-rear',
      //   status: 'rear',
      // },
      // {
      //   title: 'Вспышка',
      //   key: 'FLASH',
      //   icon: 'flash',
      //   path: 'camera-flash',
      //   status: 'flash',
      // },
      // ],
      // },
    ];

    const disTests = this.disableTests();
    testList.map((group, index) => {
      group.fields.map((test, ind) => {
        if (disTests.indexOf(test.status) !== -1) {
          // console.log('to del: ', testList[index].fields[ind].title);
          testList[index].fields.splice(ind, 1);
        }
      });
    });

    return testList;
  }

  getTestStatuses() {
    const statuses = {
      sensor: 0,
      loudSpeaker: 0,
      speaker: 0,
      mic: 0,
      headphones: 0,
      accelerometer: 0,
      compass: 0,
      gyroscope: 0,
      wifi: 0,
      bluetooth: 0,
      cellular: 0,
      gps: 0,
      lightSensor: 0,
      charger: 0,
      buttons: 0,
      vibration: 0,
      proximity: 0,
      fingerprint: 0,
      front: 0,
      rear: 0,
      flash: 0,
    };
    return statuses;
  }
  getExternalTests() {
    const testList = [
      {
        title: 'Экран',
        fields: [
          {
            title: 'Сенсорный экран',
            key: 'DISPLAY',
            icon: 'hand-right',
            path: 'screen-sensor',
            status: 'sensor',
          },
        ],
      },
      {
        title: 'Звук',
        fields: [
          // {
          //   title: 'Динамик',
          //   key: 'SPEAKER',
          //   icon: 'ear',
          //   path: 'sound-speaker',
          //   status: 'speaker',
          // },
          {
            title: 'Микрофон',
            key: 'MICROPHONE',
            icon: 'mic',
            path: 'sound-mic',
            status: 'mic',
          },
        ],
      },
      // {
      //   title: 'Подключение',
      //   fields: [
      //     {
      //       title: 'Wifi',
      //       key: 'WIFI',
      //       icon: 'wifi',
      //       path: 'connection-wifi',
      //       status: 'wifi',
      //     },
      //   ],
      // },
      // {
      //   title: 'Камера',
      //   fields: [
      //     {
      //       title: 'Задняя камера',
      //       key: 'CAMERA',
      //       icon: 'camera-outline',
      //       path: 'camera-rear',
      //       status: 'rear',
      //     },
      //   ],
      // },
    ];

    const disTests = this.disableTests();
    testList.map((group, index) => {
      group.fields.map((test, ind) => {
        if (disTests.indexOf(test.status) !== -1) {
          // console.log('to del: ', testList[index].fields[ind].title);
          testList[index].fields.splice(ind, 1);
        }
      });
    });

    return testList;
  }

  getExternalTestStatuses() {
    const statuses = {
      sensor: 0,
      mic: 0,
    };
    return statuses;
  }
  disableTests() {
    const disableTests = [];
    if (this.plt.is('ios')) {
      // console.log('plt ios');
      disableTests.push('lightSensor', 'proximity');
    }
    if (this.plt.is('android')) {
      // console.log('plt android');
    }
    disableTests.map((test, index) => {
      if (test) {
        this.setData(test, -1);
      }
    });
    return disableTests;
  }

  getTestsCount(all = false) {
    const statuses =
      this.mode === TestModes.Full
        ? this.getTestStatuses()
        : this.getExternalTestStatuses();
    let testsCount = 0;
    let testsPassed = 0;
    for (const propName in statuses) {
      if (propName) {
        const stat = Number(localStorage.getItem('status.' + propName)) || 0;
        if (stat !== -1) {
          // skip disabled tests
          if (!all && stat === 2) {
            // only passed tests
            testsPassed++;
          } else if (all && stat !== 0) {
            // all tests
            testsPassed++;
          }
          testsCount++;
        }
      }
    }

    return { testsCount, testsPassed };
  }

  getTestsReport(obj = {}) {
    const testList =
      this.mode === TestModes.Full ? this.getTests() : this.getExternalTests();
    let statuses = {};
    if (Object.keys(obj).length == 0) {
      statuses =
        this.mode === TestModes.Full
          ? this.getTestStatuses()
          : this.getExternalTestStatuses();
    } else {
      statuses = obj;
    }
    let testsNamesAlias = {};
    let testsNames = [];
    let status = '';

    testList.map((group, index) => {
      group.fields.map((test, ind) => {
        if (test.title) {
          testsNamesAlias[test.status] = test.key;
        }
      });
    });

    let stat = 0;
    for (const propName in statuses) {
      if (propName) {
        if (Object.keys(obj).length == 0) {
          stat = Number(localStorage.getItem('status.' + propName)) || 0;
        } else {
          stat = Number(obj[propName]) || 0;
        }
        switch (stat) {
          case 0:
            status = 'remove-outline';
            break;
          case 1:
            status = 'close-outline';
            break;
          case 2:
            status = 'checkmark-outline';
            break;
          default:
            status = 'remove-outline';
        }
        if (stat !== -1) {
          testsNames.push({
            name: testsNamesAlias[
              propName.charAt(0).toLowerCase() + propName.slice(1)
            ],
            key: testsNamesAlias[
              propName.charAt(0).toLowerCase() + propName.slice(1)
            ],
            status: status,
          });
        }
      }
    }

    return testsNames;
  }

  getNextTestPath(currentPath) {
    currentPath = currentPath.substr(1);
    let testList =
      this.mode === TestModes.Full ? this.getTests() : this.getExternalTests();
    switch (this.mode) {
      case TestModes.Full:
        testList = this.getTests();
        break;
      case TestModes.Express:
        testList = this.getExternalTests();
        break;
      case TestModes.Continue:
        testList = this.continueTestsList;
        break;
    }
    let path = currentPath;
    if (this.mode !== TestModes.Continue) {
      testList.map((group, index) => {
        group.fields.map((test, ind) => {
          if (test.path === currentPath) {
            if (group.fields[ind + 1]) {
              path = '/' + group.fields[ind + 1].path;
            } else {
              if (index < testList.length - 1 && testList[index].fields[0]) {
                path = '/' + testList[index + 1].fields[0].path;
              } else {
                path = '/device-report/tests';
              }
            }
          }
        });
      });
    } else {
      testList.map((test, ind) => {
        if (test === currentPath) {
          if (testList[ind + 1]) {
            path = '/' + testList[ind + 1];
          } else {
            path = '/device-report/tests';
          }
        }
      });
    }
    return path;
  }

  getPrevTestPath(currentPath) {
    currentPath = currentPath.substr(1);
    let testList =
      this.mode === TestModes.Full ? this.getTests() : this.getExternalTests();
    switch (this.mode) {
      case TestModes.Full:
        testList = this.getTests();
        break;
      case TestModes.Express:
        testList = this.getExternalTests();
        break;
      case TestModes.Continue:
        testList = this.continueTestsList;
        break;
    }
    let path = currentPath;
    if (this.mode !== TestModes.Continue) {
      testList.map((group, index) => {
        group.fields.map((test, ind) => {
          if (test.path === currentPath) {
            if (group.fields[ind - 1]) {
              path = '/' + group.fields[ind - 1].path;
            } else {
              if (
                index > 0 &&
                testList[index - 1].fields[
                  testList[index - 1].fields.length - 1
                ]
              ) {
                path =
                  '/' +
                  testList[index - 1].fields[
                    testList[index - 1].fields.length - 1
                  ].path;
              } else {
                path = '/app/tabs/home';
              }
            }
          }
        });
      });
    } else {
      testList.map((test, index) => {
        if (test === currentPath) {
          if (testList[index - 1]) {
            path = '/' + testList[index - 1];
          } else {
            if (index > 0 && testList.length - 1) {
              path = '/' + testList[length - 1];
            } else {
              path = '/buy-service/' + this.deviceId;
            }
          }
        }
      });
    }
    return path;
  }

  isLastTest(currentPath) {
    currentPath = currentPath.substr(1);
    const testList =
      this.mode === TestModes.Full ? this.getTests() : this.getExternalTests();
    let last = false;
    testList.map((group, index) => {
      group.fields.map((test, ind) => {
        if (test.path === currentPath) {
          if (
            !group.fields[ind + 1] &&
            !(index < testList.length - 1 && testList[index].fields[0])
          ) {
            last = true;
          }
        }
      });
    });
    return last;
  }

  setTestMode(mode: TestModes) {
    this.mode = mode;
  }
  getTestMode() {
    return this.mode;
  }
  lockOrientation() {
    this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
  }

  unlockOrientation() {
    this.screenOrientation.unlock();
  }

  getPermission(perm, request = true) {
    return new Promise((resolve, reject) => {
      // console.log(this.androidPermissions.PERMISSION);
      if (perm && perm === 'CAMERA') {
        this.permission = this.androidPermissions.PERMISSION.CAMERA;
      } else if (perm && perm === 'LOCATION') {
        this.permission =
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION;
      } else {
        this.permission = false;
      }
      if (this.permission) {
        this.androidPermissions.checkPermission(this.permission).then(
          (result) => {
            // console.log('Has permission?', result.hasPermission);

            if (request) {
              this.androidPermissions.requestPermissions([
                this.permission,
                this.androidPermissions.PERMISSION.GET_ACCOUNTS,
              ]);
            }
            resolve(result.hasPermission as any);
          },
          (err) => {
            console.log('Has permission?', err);
            this.androidPermissions.requestPermission(this.permission);
            reject(err as any);
          }
        );
      } else {
        console.log('Nothing to get. Please set permission params');
      }
    });
  }

  nav(path) {
    this.router.navigate([path]);
  }

  getRandom(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    const random = Math.floor(Math.random() * (max - min + 1)) + min;
    return random;
  }

  wordToNum(word) {
    word = word.toLowerCase();
    switch (word) {
      case 'один':
        word = 1;
        break;
      case 'два':
        word = 2;
        break;
      case 'три':
        word = 3;
        break;
      case 'четыре':
        word = 4;
        break;
      case 'пять':
        word = 5;
        break;
      case 'шесть':
        word = 6;
        break;
      case 'семь':
        word = 7;
        break;
      case 'восемь':
        word = 8;
        break;
      case 'девять':
        word = 9;
        break;
      case 'ноль':
        word = 0;
        break;
    }
    return word;
  }

  arraySum(array) {
    let sum = 0;
    for (let i = 0; i <= array.length - 1; i++) {
      sum = sum + Number(array[i]);
    }
    return sum;
  }

  objectToArray(obj) {
    if (Object.prototype.toString.call(obj) === '[object Object]') {
      const arr = [];
      for (let o in obj) {
        if (obj.hasOwnProperty(o)) {
          arr.push(obj[o]);
        }
      }
      return arr;
    } else {
      return obj;
    }
  }

  setData(key, val) {
    localStorage.setItem('status.' + key, val);
  }

  transformDate(date, format = 'd MM yyyy') {
    date = new Date(date);
    // date = this.datePipe.transform(date, format);
    // date = date.toLocaleDateString('default',{ day: 'numeric', month: 'long', year: 'numeric' });
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    // console.log(month);
    let monthes = {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '4': '',
      '5': '',
      '6': '',
      '7': '',
      '8': '',
      '9': '',
      '10': '',
      '11': '',
    };
    monthes = this.translate(monthes);
    return day + ' ' + monthes[month] + ' ' + year;
    // return date;
  }

  transformTime(date, format = 'HH:mm') {
    date = new Date(date);
    return this.datePipe.transform(date, format);
  }

  convertTimeZone(date, hours = 0) {
    const tzString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const lastChar = date.substr(date.length - 1);
    const newDate =
      lastChar === 'Z'
        ? moment(`${date}`).toDate()
        : moment(`${date}Z`).toDate();
    newDate.setHours(newDate.getHours() + hours);

    return moment(newDate).tz(tzString).format();
  }

  clearTestStatuses() {
    let testStatuses =
      this.mode === TestModes.Full
        ? this.getTestStatuses()
        : this.getExternalTestStatuses();
    for (const propName in testStatuses) {
      if (propName) {
        localStorage.removeItem('status.' + propName);
      }
    }
    localStorage.removeItem('status.DeviceID');
    localStorage.removeItem('status.CustomerID');
    localStorage.removeItem('status.UUID');
  }

  audioToggle(output = 'NORMAL') {
    if (output === 'SPEAKER') {
      output = AudioToggle.SPEAKER;
    } else if (output === 'EARPIECE') {
      output = AudioToggle.EARPIECE;
    } else if (output === 'RINGTONE') {
      output = AudioToggle.RINGTONE;
    } else {
      output = AudioToggle.NORMAL;
    }

    AudioToggle.setAudioMode(
      output,
      () => {},
      (err) => {
        console.log(err);
      }
    );
  }

  fillStartingPhoneNumber(e: Event) {
    let elem: HTMLInputElement;
    if (e.target) {
      elem = e.target as HTMLInputElement;
      if (elem.value === '') {
        elem.value = '+7';
      }
    }
  }

  getPhoneParams() {
    const params = [
      // 'cpu',
      // 'ram',
      // 'camRear',
      // 'camFront',
      'os',
      'screenDiagonal',
      'screenResolution',
      'screenDensity',
    ];
    // if (this.plt.is('ios')) {
    //     const toRemove = ['screenDiagonal', 'screenDensity'];
    //     params.map((param, index) => {
    //         if (toRemove.indexOf(param) !== -1) {
    //             params.splice(index, 1);
    //         }
    //     });
    // }
    // if (this.plt.is('android')) {
    // }
    return params;
  }

  getPhoneData() {
    return new Promise((resolve, reject) => {
      this.plt
        .ready()
        .then((readySource) => {
          if (readySource) {
            let info = {};
            if (this.device && this.device.cordova !== null) {
              info['name'] =
                this.device.manufacturer.toString().toUpperCase() +
                ', ' +
                this.device.model;
              info['manufacturer'] = this.device.manufacturer;
              info['model'] = this.device.model;
              info['uuid'] = this.device.uuid;
              info['serial'] = this.device.serial;
              info['osp'] = this.device.platform;
              info['osv'] = this.device.version;
              info['os'] = this.device.platform + ' ' + this.device.version;
              info['screenResolution'] =
                this.plt.height() + 'x' + this.plt.width();

              plugins.screensize.get(
                (res) => {
                  info['screenResolution'] =
                    (res.height ? res.height : '- ') +
                    'x' +
                    (res.width ? res.width : ' -');
                  info['screenDiagonal'] = res.diameter
                    ? +res.diameter.toFixed(1)
                    : '-';
                  info['screenDensity'] = res.xdpi
                    ? Math.round(+res.xdpi)
                    : '-';

                  // info['cpu'] = '-';
                  // info['ram'] = '-';
                  // info['camRear'] = '-';
                  // info['camFront'] = '-';

                  // chrome.system.cpu.getInfo((res) => {
                  //     console.log(res);
                  //     // info['cpu'] = res;
                  // }, (err) => {
                  //     console.log(err);
                  // }); // TODO : transfer values

                  // chrome.system.memory.getInfo((res) => {
                  //     console.log(res);
                  //     // info['ram'] = res;
                  // }, (err) => {
                  //     console.log(err);
                  // }); // TODO : transfer values

                  resolve(info);
                },
                (err) => {
                  console.log(err);
                  reject(err);
                }
              );
            }

            // if (!(typeof deviceProps !== 'undefined' && deviceProps.length > 0)) {
            // } else {
            // }
          }
        })
        .catch((err) => console.log(err));
    });
  }

  openWebPage(url, useSystemBrowser = false) {
    if (!this.isForWeb()) {
      // const options = new InAppBrowserOptions();
      // options.location = 'no';
      const browser = this.inAppBrowser.create(url, '_system', {
        location: 'yes',
        toolbar: 'yes',
        disallowoverscroll: 'yes',
        hidenavigationbuttons: 'yes',
      });
      const onLoadStop = browser.on('loadstop');
      if (onLoadStop) {
        onLoadStop.subscribe((e) => {
          browser.insertCSS({
            code: '.header__logo > img {display: none !important;}',
          });
          // alert(7);
        });
      }
      return browser;
    } else {
      window.open(url);
    }
  }

  translate(translate, params = {}) {
    this.translateService
      .get(Object.keys(translate), params)
      .subscribe((res: any) => {
        Object.keys(translate).map((key, index) => {
          translate[key] = res[key];
        });
      });
    return translate;
  }

  getParamValueQueryString(paramName) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  setLanguage(lang) {
    localStorage.setItem('lang', lang);
    this.translateService.use(lang);
    this.translateService.get('BACK').subscribe((backLabel) => {
      this.config.set('backButtonText', backLabel);
    });
  }

  SHA1(msg) {
    function rotate_left(n, s) {
      var t4 = (n << s) | (n >>> (32 - s));
      return t4;
    }
    function lsb_hex(val) {
      var str = '';
      var i;
      var vh;
      var vl;
      for (i = 0; i <= 6; i += 2) {
        vh = (val >>> (i * 4 + 4)) & 0x0f;
        vl = (val >>> (i * 4)) & 0x0f;
        str += vh.toString(16) + vl.toString(16);
      }
      return str;
    }
    function cvt_hex(val) {
      var str = '';
      var i;
      var v;
      for (i = 7; i >= 0; i--) {
        v = (val >>> (i * 4)) & 0x0f;
        str += v.toString(16);
      }
      return str;
    }
    function Utf8Encode(string) {
      string = string.replace(/\r\n/g, '\n');
      var utftext = '';
      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);
        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }
      return utftext;
    }
    var blockstart;
    var i, j;
    var W = new Array(80);
    var H0 = 0x67452301;
    var H1 = 0xefcdab89;
    var H2 = 0x98badcfe;
    var H3 = 0x10325476;
    var H4 = 0xc3d2e1f0;
    var A, B, C, D, E;
    var temp;
    msg = Utf8Encode(msg);
    var msg_len = msg.length;
    var word_array = new Array();
    for (i = 0; i < msg_len - 3; i += 4) {
      j =
        (msg.charCodeAt(i) << 24) |
        (msg.charCodeAt(i + 1) << 16) |
        (msg.charCodeAt(i + 2) << 8) |
        msg.charCodeAt(i + 3);
      word_array.push(j);
    }
    switch (msg_len % 4) {
      case 0:
        i = 0x080000000;
        break;
      case 1:
        i = (msg.charCodeAt(msg_len - 1) << 24) | 0x0800000;
        break;
      case 2:
        i =
          (msg.charCodeAt(msg_len - 2) << 24) |
          (msg.charCodeAt(msg_len - 1) << 16) |
          0x08000;
        break;
      case 3:
        i =
          (msg.charCodeAt(msg_len - 3) << 24) |
          (msg.charCodeAt(msg_len - 2) << 16) |
          (msg.charCodeAt(msg_len - 1) << 8) |
          0x80;
        break;
    }
    word_array.push(i);
    while (word_array.length % 16 != 14) word_array.push(0);
    word_array.push(msg_len >>> 29);
    word_array.push((msg_len << 3) & 0x0ffffffff);
    for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
      for (i = 0; i < 16; i++) W[i] = word_array[blockstart + i];
      for (i = 16; i <= 79; i++)
        W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
      A = H0;
      B = H1;
      C = H2;
      D = H3;
      E = H4;
      for (i = 0; i <= 19; i++) {
        temp =
          (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5a827999) &
          0x0ffffffff;
        E = D;
        D = C;
        C = rotate_left(B, 30);
        B = A;
        A = temp;
      }
      for (i = 20; i <= 39; i++) {
        temp =
          (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) &
          0x0ffffffff;
        E = D;
        D = C;
        C = rotate_left(B, 30);
        B = A;
        A = temp;
      }
      for (i = 40; i <= 59; i++) {
        temp =
          (rotate_left(A, 5) +
            ((B & C) | (B & D) | (C & D)) +
            E +
            W[i] +
            0x8f1bbcdc) &
          0x0ffffffff;
        E = D;
        D = C;
        C = rotate_left(B, 30);
        B = A;
        A = temp;
      }
      for (i = 60; i <= 79; i++) {
        temp =
          (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) &
          0x0ffffffff;
        E = D;
        D = C;
        C = rotate_left(B, 30);
        B = A;
        A = temp;
      }
      H0 = (H0 + A) & 0x0ffffffff;
      H1 = (H1 + B) & 0x0ffffffff;
      H2 = (H2 + C) & 0x0ffffffff;
      H3 = (H3 + D) & 0x0ffffffff;
      H4 = (H4 + E) & 0x0ffffffff;
    }
    temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);

    return temp.toLowerCase();
  }

  getLanguages() {
    return new Promise((resolve, reject) => {
      if (!this.langs.length) {
        this.apiService.getLangs().then((r: object[]) => {
          this.langs.push(...r);
          resolve(this.langs);
        });
      } else {
        resolve(this.langs);
      }
    });
  }
}

export enum TestModes {
  Express = 1,
  Full,
  Continue,
}

export enum PaymentSystem {
  FreedomPay,
  ApplePay,
  GooglePay,
  CloudPayment,
  CashInOffice,
  ClickUz,
  Stripe,
  FreedomBank,
  FreedomPayUz,
  TelegramPay,
}

export enum reportStatus {
  OUTDATED,
  INVALID,
  VALID,
}
